@import 'variables';

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.color-gray {
  color: $gray-50;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.z-0 {
  z-index: 0 !important;
}

.settings-btn-group {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  right: -1px;
  margin-top: map-get($map: $spacers, $key: 4);

  @media (max-width: 1250px) {
    flex-direction: row !important;
    margin: 0 0 0 map-get($map: $spacers, $key: 4) !important;
  }

  & :first-child > button {
    border-top-left-radius: 10px !important;
    right: 1px;

    @media (max-width: 1250px) {
      border-top-left-radius: 10px !important;
    }
  }

  & :last-child > button {
    border-bottom-left-radius: 10px !important;

    @media (max-width: 1250px) {
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 10px !important;
    }
  }
}

.word-wrap {
  word-wrap: anywhere;
  overflow-wrap: anywhere;
}

.table td {
  width: 100%;
}

.form-control {
  font-size: 13px !important;
}

.rc-slider-disabled {
  background-color: rgba(0, 0, 0, 0) !important;
}