.operating-systems {
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media screen and (max-width: 1340px) {
    overflow: auto;
    overflow-x: hidden;
    flex-direction: column;
  }

  &__table {
    margin-top: 16px;
    flex-grow: 1;
    padding-right: 8px;
    overflow: auto;
    margin-bottom: 24px;
    @media screen and (max-width: 1340px) {
      min-height: 300px;
      margin-top: 280px;
      order: 2;
      overflow: visible;
    }
  }

  &__chart {
    position: relative;
    width: 800px;
    max-height: 100px;
    @media screen and (max-width: 1340px) {
      order: 1;
    }
  }
}