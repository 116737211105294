@import 'src/variables';

.reviews-tab {
  overflow: hidden;
  display: flex;
  width: 100%;

  @media (max-width: map-get($breakpoints, lg)) {
    flex-direction: column;
    overflow: auto;
  }

  .reviews-tab__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;

    @media (max-width: map-get($breakpoints, lg)) {
      order: 2;
    }
  
    .reviews-tab__header {
      display: flex;
      justify-content: space-between;
      padding: 20px 24px;
 
    }

    .review-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      overflow: hidden;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 620px;
        max-width: 100%;
        flex-wrap: wrap;
        // position: sticky;
        // top: 0;
        padding: 8px 0;

        @media (max-width: 768px) {
          flex-wrap: wrap;
          padding-left: 10px;
        }
      }

      .review {
        align-self: center;
        padding: 12px 24px 24px;
        margin: 6px;
        min-width: 0px;
        width: 100%;
        max-width: 620px;
        border-radius: 2px;

        .text-gray-3 {
          color: $gray-30;
        }

        .text-gray-5 {
          color: $gray-50;
        }

        &__header {
          display: flex;
          justify-content: space-between;
          align-items: baseline;

          .logo {
            display: flex;
            align-items: flex-end;

            &__company-name {
              font-size: 0.75em;
            }

            &__product-name {
              line-height: 10px;
            }
          }
        }

        &__reason {
          word-wrap: break-word;
          padding: 16px 0;
        }

        &__footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &_dark {
          background-color: $gray-80;
        }

        @media (max-width: 768px) {
          width: 444px;
        }

        @media (max-width: 360px) {
          width: 312px;
        }
      }
    }
  }

  .review-filter-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 360px;
    min-width: 360px;
    max-width: 360px;

    @media (max-width: map-get($breakpoints, lg)) {
      max-width: 100%;
      width: 100%;
    }
    // padding: 16px 16px 0 16px;

    .submit-button {
      margin: 24px 0 8px;
      padding: 12px 0;
    }
  }
}
